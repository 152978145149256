declare global {
  interface Window {
    apiElectron: {
      sendMessage: (message: string) => void,
    }
  }
}

export const openOfflineMode = () => {
  window.apiElectron.sendMessage('openOfflineMode');
};
