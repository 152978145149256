import React from 'react';
import { FormattedMessage } from 'react-intl';

import { SelfServiceType } from 'modules/selfServices/types/SelfService';

import { b } from 'utils/i18nUtils';

import { ButtonType } from 'components/ui/Button';
import { Button, Modal, Input } from 'components/ui';
import KioskWarningModal from 'components/Home/KioskWarningModal';
import useLoginRedirection from 'components/Home/useLoginRedirection';

interface BreakdownRedirectionModalProps {
  plateNumber: string;
  onCancel: () => void;
}

const BreakdownRedirectionModal: React.FC<BreakdownRedirectionModalProps> = ({ plateNumber, onCancel }) => {
  const [showError, setShowError] = React.useState(false);

  const handleContinue = useLoginRedirection({ onError: setShowError });

  const handleClick = React.useCallback(
    () => handleContinue({ type: SelfServiceType.BREAKDOWN }),
    [handleContinue],
  );

  return (
    <>
      {showError && <KioskWarningModal onClose={setShowError} />}
      {!showError && (
        <Modal
          open
          onClose={onCancel}
          title={<FormattedMessage id="home.breakdown" defaultMessage="No appointment" tagName="h2" />}
        >
          <div className="flex flex-col gap-8 items-center">
            <FormattedMessage
              id="login.breakdownWarningModal.noMatch"
              defaultMessage="We could <b>not find</b> a file matching the following plate:"
              values={{ b }}
              tagName="p"
            />
            <Input value={plateNumber} disabled center inputClassName="!p-6 !w-fit" />
            <hr className="w-full" />
            <FormattedMessage
              id="login.breakdownWarningModal.continue"
              defaultMessage="If you do not have an appointment, <b>you may still continue</b>."
              values={{ b }}
              tagName="p"
            />

            <Button onClick={handleClick} className="w-full" testId="continue">
              <FormattedMessage
                id="login.breakdownWarningModal.continueButton"
                defaultMessage="Continue without appointment"
              />
            </Button>
            <Button
              testId="cancel"
              onClick={onCancel}
              type={ButtonType.SECONDARY}
              className="w-full"
            >
              <FormattedMessage id="steps.back" defaultMessage="Back" />
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default BreakdownRedirectionModal;
