import { PhoneNumber } from 'modules/selfServices/types/SelfService';

export enum Reason {
  ACCIDENT = 'ACCIDENT',
  BREAKDOWN = 'BREAKDOWN',
  DELIVERY = 'DELIVERY',
  MAINTENANCE = 'MAINTENANCE',
  OTHER = 'OTHER',
}

export const ALL_REASONS = Object.values(Reason);
export const DRIVER_REASONS = ALL_REASONS.filter((reason) => reason !== Reason.DELIVERY);

export interface BreakdownInfo {
  breakdownServiceComment?: string;
  breakdownServiceReason?: Reason,
  customerFirstName?: string,
  customerLastName?: string,
  customerPhoneNumber?: PhoneNumber,
}
