import { createDraftSafeSelector } from '@reduxjs/toolkit';

import STEPS from 'components/SelfServices/Steps';

import { getOrigin } from 'modules/dealers/selectors';
import { getSelfServiceType } from 'modules/selfServices/selectors';

import type { RootState } from 'App/Store';
import { SelfServiceOrigin, SelfServiceType } from 'modules/selfServices/types/SelfService';

const getState = (state: RootState) => state.steps;

export const getCurrentStep = createDraftSafeSelector(getState, (steps) => steps.currentStep);

const getSteps = createDraftSafeSelector(
  getOrigin,
  getSelfServiceType,
  (origin: SelfServiceOrigin, type: SelfServiceType) => Object.values(STEPS[type][origin]),
);

export const isLastStep = createDraftSafeSelector(
  getCurrentStep,
  getSteps,
  (step, steps) => steps[steps.length - 1].key === step,
);
