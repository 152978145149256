import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { Brand } from 'types/Brand';
import { RootState } from 'App/Store';
import { uniq } from 'utils/objectUtils';
import { PoliciesEnum } from 'modules/auth/types/Policies';
import { AllSelfServiceTypes, SelfServiceOrigin, SelfServiceType } from 'modules/selfServices/types/SelfService';

import type InitialState from 'modules/dealers/types/InitialState';

import { HelpLinePhoneNumber } from './types/HelpLinePhoneNumber';
import {
  ConnectionTypeLogin,
  ContextResponse,
  Dealers,
  KioskConnectionStatus,
  KioskLocation,
  KioskType,
} from './types/ContextResponse';

const getState = (state: RootState) => state.dealers;

const getProps = (_: RootState, props: string) => props;

export const getContext = createDraftSafeSelector(
  getState,
  ({ context }: InitialState) => context,
);
export const getOrigin = createDraftSafeSelector(
  getContext,
  (context) => context?.origin,
);

export const getKioskType = createDraftSafeSelector(
  getContext,
  (context) => context?.kioskType,
);

export const getKioskName = createDraftSafeSelector(
  getContext,
  (context) => context?.kioskName,
);

export const getKioskTypes = createDraftSafeSelector(getContext, (context) => context?.kioskTypes ?? []);

export const getKioskId = createDraftSafeSelector(
  getContext,
  (context) => context?.kioskId,
);

export const getKioskLocation = createDraftSafeSelector(getContext, (context) => context?.kioskLocation ?? KioskLocation.OUTDOOR);

export const getDealerCustomerLanguage = createDraftSafeSelector(
  getContext,
  (context) => context?.dealerCustomerLanguage,
);

export const getContextSelfServiceType = createDraftSafeSelector(
  getContext,
  (context) => context?.type,
);

export const getLoginToken = createDraftSafeSelector(
  getContext,
  (context) => context?.token,
);

export const getConnectionType = createDraftSafeSelector(
  getContext,
  (context) => context?.connectionType,
);

export const isKioskOrigin = createDraftSafeSelector(
  getContext,
  (context) => context?.origin === SelfServiceOrigin.KIOSK,
);

export const isShareboxIndoorKiosk = createDraftSafeSelector(
  getKioskType,
  getKioskLocation,
  getConnectionType,
  (kioskType, kioskLocation, connectionType) => (
    kioskType === KioskType.SHAREBOX
    && kioskLocation === KioskLocation.INDOOR
    && connectionType === ConnectionTypeLogin.MFA
  ),
);

// Use to know if the self-service flow will be done remotely (using the mobile phone for instance)
export const isRemoteKiosk = createDraftSafeSelector(
  isKioskOrigin,
  getKioskType,
  isShareboxIndoorKiosk,
  (isKiosk, kioskType, shareboxIndoorKiosk) => isKiosk && kioskType === KioskType.SHAREBOX && !shareboxIndoorKiosk,
);

// Use to know if the self-service flow will be done physically on the kiosk
export const isLocalKiosk = createDraftSafeSelector(
  isKioskOrigin,
  isRemoteKiosk,
  (isKiosk, remoteKiosk) => isKiosk && !remoteKiosk,
);

export const isPublicOnSite = createDraftSafeSelector(
  getContext,
  (context) => context?.isPublic && context?.origin === SelfServiceOrigin.ON_SITE,
);

export const isPublicDevice = createDraftSafeSelector(
  getContext,
  isLocalKiosk,
  (context, localKiosk) => context?.isPublic || localKiosk,
);

export const getSelfServicePhoneNumber = createDraftSafeSelector(
  getContext,
  (context) => context?.selfServicePhoneNumber,
);

export const getFormattedSelfServicePhoneNumber = createDraftSafeSelector(
  getSelfServicePhoneNumber,
  (phoneNumber) => {
    if (phoneNumber) {
      return `+${phoneNumber.prefix} ${phoneNumber.local}`;
    }

    return undefined;
  },
);

export const getKioskDealers = createDraftSafeSelector(
  getContext,
  (context: ContextResponse) => context?.kioskDealers ?? [],
);

export const getKioskDealersWithBreakdown = createDraftSafeSelector(
  getOrigin,
  getKioskDealers,
  (origin, dealers) => dealers.filter(
    (dealer: Dealers) => dealer.selfServiceAvailableTypes[SelfServiceType.BREAKDOWN]?.includes(origin),
  ),
);

export const getBrands = createDraftSafeSelector(getContext, (context) => context?.brands);

export const getDealerId = createDraftSafeSelector(
  getContext,
  getKioskDealersWithBreakdown,
  (context, kioskDealers) => context?.id ?? kioskDealers?.[0]?.id,
);

export const getBrandsByDealerId = createDraftSafeSelector(
  getKioskDealersWithBreakdown,
  getBrands,
  getProps,
  (kioskDealers, brands, dealerId) => kioskDealers?.find(({ id }: Dealers) => id === dealerId)?.brands ?? brands,
);

export const getDefaultBrandId = createDraftSafeSelector(
  getBrandsByDealerId,
  (brands) => brands?.[0]?.id,
);

export const getPrefix = createDraftSafeSelector(
  getContext,
  (context) => context?.dealerCountry?.prefix,
);

export const getPrefixByDealerId = createDraftSafeSelector(
  getKioskDealersWithBreakdown,
  getPrefix,
  getProps,
  (kioskDealers, prefix, dealerId) => prefix ?? kioskDealers?.find(
    ({ id }: Dealers) => id === dealerId,
  )?.dealerCountry?.prefix,
);

export const getFormattedBrands = createDraftSafeSelector(
  getBrandsByDealerId,
  (brands: Array<Brand>) => brands?.reduce<Record<string, string>>(
    (acc, { name, id }) => ({ ...acc, [id]: name }),
    {},
  ),
);

export const getFormattedDealers = createDraftSafeSelector(
  getKioskDealersWithBreakdown,
  (dealers: Array<Dealers>) => dealers?.reduce<Record<string, string>>(
    (acc, { dealerName, id }) => ({ ...acc, [id]: dealerName }),
    {},
  ),
);

export const getHelpLinePhoneNumbers = createDraftSafeSelector(getContext, (context: ContextResponse) => {
  if (context?.selfServicePhoneNumber) {
    return [{ selfServicePhoneNumber: context.selfServicePhoneNumber }] as HelpLinePhoneNumber[];
  }

  if (!context?.dealerName && context?.kioskDealers) {
    return Object.values(
      context.kioskDealers.reduce((acc, dealer) => {
        if (dealer?.selfServicePhoneNumber) {
          acc[dealer.id] = { dealerName: dealer.dealerName, selfServicePhoneNumber: dealer.selfServicePhoneNumber };
        }
        return acc;
      }, {} as Record<string, HelpLinePhoneNumber>),
    );
  }

  return [];
});

export const shouldDisplayPhoneNumbers = createDraftSafeSelector(
  getContext,
  getHelpLinePhoneNumbers,
  (context, phoneNumbers) => SelfServiceOrigin.KIOSK === context?.origin && phoneNumbers.length > 0,
);

export const isKioskFull = createDraftSafeSelector(
  getContext,
  (context) => context?.origin === SelfServiceOrigin.KIOSK && !context.isAvailable,
);

export const getPolicies = createDraftSafeSelector(
  getContext,
  ({ generalTermsConditionsLink, confidentialityPolicyLink }) => [
    ...(generalTermsConditionsLink
      ? [{ link: generalTermsConditionsLink, type: PoliciesEnum.GENERAL_TERMS_CONDITION }]
      : []
    ),
    ...(confidentialityPolicyLink
      ? [{ link: confidentialityPolicyLink, type: PoliciesEnum.CONFIDENTIALITY_POLICY }]
      : []
    ),
  ],
);

export const isKioskReadyOptional = createDraftSafeSelector(getKioskType, (type) => type === KioskType.SHAREBOX);

export const isKioskEmergencyMode = createDraftSafeSelector(getContext, (context) => context?.isEmergencyMode);

const isKioskOffline = createDraftSafeSelector(
  getContext,
  (context) => context?.kioskConnectionStatus === KioskConnectionStatus.OFFLINE,
);

export const hasErrorOnKiosk = createDraftSafeSelector(
  isKioskOffline,
  isKioskEmergencyMode,
  (...args) => args.some(Boolean),
);

const getDealerById = createDraftSafeSelector(
  getKioskDealers,
  getProps,
  (dealers, dealerId) => dealers?.find(({ id }) => dealerId === id),
);

export const getTimezoneByDealerId = createDraftSafeSelector(
  getDealerById,
  (dealer) => dealer?.timezone,
);

export const getOffsetTimezoneByDealerId = createDraftSafeSelector(
  getDealerById,
  getTimezoneByDealerId,
  (dealer, timezone) => dealer?.dealerCountry?.timezones?.[timezone],
);

export const getLocationId = createDraftSafeSelector(
  getContext,
  (context) => context?.kioskLocationId,
);

export const displayOriginSelection = createDraftSafeSelector(
  getOrigin,
  getKioskTypes,
  getLocationId,
  getContextSelfServiceType,
  (origin, kioskTypes, locationId, type) => (
    origin === SelfServiceOrigin.HOME
    && kioskTypes.includes(KioskType.SHAREBOX)
    // For the check-out, if the locationId is undefined it means that keys are not in the kiosk
    && (type === SelfServiceType.CHECK_IN || Boolean(locationId))
  ),
);

export const getSelfServiceAvailableTypes = createDraftSafeSelector(
  getContext,
  getKioskDealers,
  (context, dealers): Partial<Record<SelfServiceType, SelfServiceOrigin[]>> => {
    if (context?.kioskId) {
      return AllSelfServiceTypes.reduce((acc, type) => ({
        ...acc,
        [type]: uniq(dealers.flatMap((dealer) => dealer.selfServiceAvailableTypes?.[type] ?? [])),
      }), {});
    }
    return context?.selfServiceAvailableTypes ?? {};
  },
);

export const isBreakdownAvailable = createDraftSafeSelector(
  getSelfServiceAvailableTypes,
  getOrigin,
  (availableTypes, origin) => availableTypes[SelfServiceType.BREAKDOWN]?.includes(origin) ?? false,
);

export const isAtDealershipFlow = createDraftSafeSelector(
  isRemoteKiosk,
  getConnectionType,
  (remoteKiosk, connectionType) => remoteKiosk && connectionType === ConnectionTypeLogin.TOKEN,
);

/**
 * Intended to be used in the error page to display the correct message, hence the fallback on the 404 status code.
 */
export const getContextRequestStatus = createDraftSafeSelector(
  getState,
  ({ status }) => status,
);

export const getDealerName = createDraftSafeSelector(
  getContext,
  (context) => context?.dealerName,
);

export const isContextExpired = createDraftSafeSelector(
  getContext,
  (context) => context?.isTokenExpired,
);

export const getPaymentMethods = createDraftSafeSelector(
  getContext,
  (context) => context?.paymentMethods?.methods,
);
