import React from 'react';
import { useSelector } from 'react-redux';
import { FieldValidator } from 'final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormRenderProps, useField } from 'react-final-form';

import {
  ALL_REASONS, BreakdownInfo, DRIVER_REASONS, Reason,
} from 'types/BreakdownInfo';
import PageBaseProps from 'types/PageBase';

import { formatSelectData, required } from 'utils/formUtils';

import { getLoginData } from 'modules/auth/selectors';

import PageFooter from '../ui/PageFooter';
import SelectInput from '../ui/SelectInput';
import FormTextarea from '../ui/form/FormTextarea';

const validateComment: FieldValidator<string> = (comment, { breakdownServiceReason }: BreakdownInfo) => {
  const isMandatory = breakdownServiceReason?.includes(Reason.OTHER);
  return isMandatory ? required(comment) : undefined;
};

const Content: React.FC<PageBaseProps & FormRenderProps> = ({
  onPrev, handleSubmit, initialValues, pristine, hasValidationErrors, shouldDisplayBackButton,
}) => {
  const intl = useIntl();

  const { isDriver } = useSelector(getLoginData);

  const { input: reasonInput } = useField('breakdownServiceReason', { subscription: { value: true } });

  const reasonInputData = React.useMemo(() => {
    const reasons = isDriver ? DRIVER_REASONS : ALL_REASONS;
    return formatSelectData(reasons, intl, 'breakdownInfo.reason');
  }, [intl, isDriver]);

  const displaySkipText = pristine && !initialValues;

  return (
    <div className="main-content">
      <div className="flex flex-col items-center">
        <FormattedMessage
          tagName="h1"
          id="breakdownInfo.title"
          defaultMessage="Information"
        />
        <div className="description mt-5 text-center">
          <FormattedMessage
            id="breakdownInfo.description"
            defaultMessage="Fill in the customer’s contact information and provide details about the breakdown."
            tagName="p"
          />
        </div>
      </div>
      <div className="content">
        <SelectInput
          {...reasonInput}
          data={reasonInputData}
          data-testid="reason-input"
          label={intl.formatMessage({ id: 'breakdownInfo.reasonLabel', defaultMessage: 'Reason for tow' })}
        />
        <FormTextarea
          data-testid="comment"
          validate={validateComment}
          name="breakdownServiceComment"
          className="w-full px-5 kiosk:px-10"
          label={intl.formatMessage({ id: 'breakdownInfo.comment', defaultMessage: 'Requests/comments' })}
        />
      </div>
      <PageFooter
        onPrev={onPrev}
        onNext={handleSubmit}
        disabled={hasValidationErrors}
        shouldDisplayBackButton={shouldDisplayBackButton}
        nextText={displaySkipText ? { id: 'breakdownInfo.skip', defaultMessage: 'Skip' } : undefined}
      />
    </div>
  );
};

export default Content;
