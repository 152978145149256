import React from 'react';
import { FormattedDate } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import { PAGE_SIZE } from 'modules/chat/constants';
import { setPageNumber } from 'modules/chat/actions';

import { useGetMessagesMutation, useReadMessagesMutation } from 'modules/chat/service';

import {
  getFirstMessageId,
  getFirstUnreadId,
  getGroupedMessages,
  getLastMessage,
  getPageNumber,
  getTotalPages,
  hasMessages as hasMessagesSelector,
  haveUnreadMessages as haveUnreadMessagesSelector,
} from 'modules/chat/selectors';

import Spinner from 'components/ui/Spinner';

import Arrow from 'assets/icons/arrow-chat.svg';

import MessageContent from './MessageContent';

type MessageListProps = {
  selfServiceId: string;
};

const BOTTOM_THRESHOLD = 50;

/**
 * Component that displays a list of messages fetched from the API.
 * This *needs* to be a component to allow refetching messages when it's re-mounted.
 */
const MessageList: React.FC<MessageListProps> = ({ selfServiceId }) => {
  const dispatch = useDispatch();
  const timeoutId = React.useRef<ReturnType<typeof setTimeout>>();
  const [isAtBottom, setIsAtBottom] = React.useState(false);

  const scrollRef = React.useRef<HTMLDivElement>();

  const pageNumber = useSelector(getPageNumber);
  const totalPages = useSelector(getTotalPages);
  const groupedMessages = useSelector(getGroupedMessages);

  const firstUnreadId = useSelector(getFirstUnreadId);
  const firstMessageId = useSelector(getFirstMessageId);

  const lastMessage = useSelector(getLastMessage);
  const hasMessages = useSelector(hasMessagesSelector);
  const hasUnreadMessages = useSelector(haveUnreadMessagesSelector);

  const [readMessages, { isLoading: isReading }] = useReadMessagesMutation();
  const [fetchMessages, {
    isLoading, isUninitialized, isSuccess, reset,
  }] = useGetMessagesMutation({ fixedCacheKey: 'GET/MESSAGES' });

  const hasNextPage = pageNumber < totalPages - 1;

  const handleLoadMore = React.useCallback(() => dispatch(setPageNumber(pageNumber + 1)), [dispatch, pageNumber]);

  const [loadMoreRef, { rootRef }] = useInfiniteScroll({
    hasNextPage,
    loading: isLoading,
    onLoadMore: handleLoadMore,
    rootMargin: '0px 0px 24px 0px',
  });

  const initRef = React.useCallback(
    (ref: HTMLDivElement) => {
      scrollRef.current = ref;
      rootRef(ref);
    },
    [rootRef],
  );

  const handleToBottom = React.useCallback(
    (behavior: ScrollBehavior = 'smooth') => () => {
      const scrollableContainer = scrollRef.current as HTMLDivElement;
      if (scrollableContainer) {
        const firstUnreadElement = document.getElementById(firstUnreadId) as HTMLDivElement;
        if (!firstUnreadElement) {
          scrollableContainer.scrollTo({
            behavior,
            top: scrollableContainer.scrollHeight,
          });
        } else {
          const unreadOffset = firstUnreadElement.offsetTop;
          const scrollPos = scrollableContainer.scrollTop;
          const unreadBottomPos = unreadOffset + firstUnreadElement.offsetHeight;
          const scrollBottomPos = scrollPos + scrollableContainer.clientHeight;

          if ((scrollBottomPos >= unreadBottomPos) || (scrollPos >= unreadOffset)) {
            scrollableContainer.scrollTo({
              behavior,
              top: scrollableContainer.scrollHeight,
            });
          } else {
            firstUnreadElement.scrollIntoView({ behavior });
          }
        }
      }
    },
    [firstUnreadId],
  );

  const handleRead = React.useCallback(() => {
    if (!timeoutId.current) {
      timeoutId.current = setTimeout(() => {
        readMessages(selfServiceId);
        timeoutId.current = undefined;
      }, 10000);
    }
  }, [selfServiceId, readMessages]);

  const checkIsAtBottom = React.useCallback(() => {
    const container = scrollRef.current;
    if (container) {
      const isBottom = container.scrollHeight - container.scrollTop - container.clientHeight <= BOTTOM_THRESHOLD;
      setIsAtBottom(isBottom);
    }
  }, []);

  // Read messages when scrolling to the bottom
  const handleOnScroll = React.useCallback(
    ({ target }: React.UIEvent<HTMLDivElement>) => {
      checkIsAtBottom();
      if (hasUnreadMessages && !isReading && target instanceof HTMLElement) {
        const scrollPosition = target.scrollTop + target.clientHeight;
        if (scrollPosition >= target.scrollHeight - 24) {
          handleRead();
        }
      }
    },
    [hasUnreadMessages, isReading, handleRead, checkIsAtBottom],
  );

  // Read messages on mount
  React.useEffect(() => {
    if (isSuccess && pageNumber === 0 && hasUnreadMessages) {
      handleRead();
    }
  }, [isSuccess, pageNumber, hasUnreadMessages, handleRead]);

  // Reset + clear timeout on unmount
  React.useEffect(() => () => {
    reset();
    dispatch(setPageNumber(0));
    clearTimeout(timeoutId.current);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch messages on page change
  React.useEffect(() => {
    fetchMessages({ selfServiceId, page: pageNumber, size: PAGE_SIZE });
  }, [pageNumber, fetchMessages, selfServiceId]);

  // Init + Scroll to first fetched message (infinite scroll)
  React.useLayoutEffect(() => {
    if (!isLoading && hasMessages) {
      if (pageNumber === 0) {
        document
          .getElementById(firstUnreadId ?? lastMessage?.id)
          ?.scrollIntoView({ behavior: 'instant', block: firstUnreadId ? 'start' : 'end' });
      } else {
        requestAnimationFrame(() => {
          document.getElementById(firstMessageId)?.scrollIntoView({ behavior: 'instant', block: 'end' });
        });
      }
      checkIsAtBottom();
    }
    // We only listen to `isFetching` toggling to avoid reacting on websocket/sent messages
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  // Scroll bottom new message sent
  React.useLayoutEffect(() => {
    if (lastMessage?.createdBy?.onCustomerBehalf) {
      requestAnimationFrame(() => {
        scrollRef.current?.scrollTo({ top: scrollRef.current.scrollHeight });
      });
      checkIsAtBottom();
    }
  }, [lastMessage, checkIsAtBottom]);

  return (
    <div className="relative overflow-hidden messages">
      <div
        className="overflow-y-auto py-1 px-2 flex flex-col gap-2 h-full"
        ref={initRef}
        onScroll={handleOnScroll}
        data-testid="messages"
      >
        {(isUninitialized || isLoading || hasNextPage) && (
          <div>
            <Spinner data-testid="chat-loader" ref={loadMoreRef} />
          </div>
        )}
        {groupedMessages.map((group) => (
          <React.Fragment key={group.date}>
            <span className="text-center my-6">
              <FormattedDate value={group.date} month="long" day="numeric" weekday="long" />
            </span>
            {group.messages.flatMap((messages) => messages.map((message, index) => {
              const isFirstUnread = message.id === firstUnreadId;
              const showAuthor = index === 0;
              const showFooter = index === messages.length - 1;
              return (
                <MessageContent
                  key={message.id}
                  {...message}
                  isFirstUnread={isFirstUnread}
                  showAuthor={showAuthor}
                  showFooter={showFooter}
                />
              );
            }))}
          </React.Fragment>
        ))}
      </div>
      {hasMessages && !isAtBottom && (
        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        <button
          type="button"
          onClick={handleToBottom()}
          className="absolute flex justify-center items-center shadow w-16 h-16 rounded-full bg-white bottom-0.5 left-1/2 transform -translate-x-1/2 cursor-pointer hover:bg-secondary"
        >
          <Arrow width="24" height="24" />
        </button>
      )}
    </div>
  );
};

export default MessageList;
