import React from 'react';
import { addSeconds } from 'date-fns';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { isApiErrorResponse } from 'types/Error';
import { ButtonType } from 'components/ui/Button';

import useCountdown from 'hooks/useCountdown';
import { useDispatch, useSelector } from 'hooks';

import { getAdminCodeAttempt, getQRCodeToken } from 'modules/auth/selectors';

import { resetAdminCodeAttempt } from 'modules/auth/actions';

import { useLoginAdminMutation } from 'modules/auth/service';
import { useGetContextQuery } from 'modules/dealers/service';

import {
  Button, Input, PageHeader, Spinner,
} from 'components/ui';
import Footer from 'components/ui/PageFooter';

import AdminIcon from 'assets/icons/admin.svg';

const AdminLogin: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();

  const codeAttempt = useSelector(getAdminCodeAttempt);
  const qrCodeToken = useSelector(getQRCodeToken);

  const [pinCode, setPinCode] = React.useState('');

  const [login, { isLoading, error, isError }] = useLoginAdminMutation();
  const { data: contextData, isFetching } = useGetContextQuery();

  const [date, setDate] = React.useState(new Date());
  const { countdown } = useCountdown({ date });

  const disabled = isLoading || (error && countdown > 0) || pinCode.length < 6;
  const kioskId = contextData?.kioskId;

  const handleNextClick = React.useCallback(async () => {
    try {
      await login({ qrCodeToken, kioskId, pinCode }).unwrap();
    } catch (e) {
      if (codeAttempt > 1) {
        setDate(addSeconds(Date.now(), 60));
      }
      setPinCode('');
    }
  }, [codeAttempt, kioskId, login, pinCode, qrCodeToken]);

  const handleInputChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value.length <= 6 && value.match(/^[0-9]*$/)) {
      setPinCode(event.target.value);
    }
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !disabled) {
      handleNextClick();
    }
  };

  const inputError = isApiErrorResponse(error) ? (
    <FormattedMessage
      id={`error.types.${error.data.errorType}`}
      defaultMessage={`Error: ${error.data.errorMessage}`}
      values={{ countdown }}
    />
  ) : (
    isError
  );

  const handleOnPrev = React.useCallback(() => {
    dispatch(resetAdminCodeAttempt());
    navigate(`/${search}`);
  }, [dispatch, navigate, search]);

  return (
    <div className="flex flex-col">
      <PageHeader hideSteps />
      {isFetching && <Spinner />}
      {!isFetching && (
        <div className="main-content">
          <FormattedMessage id="admin.title" defaultMessage="Administration interface" tagName="h1" />
          <div className="content !m-auto">
            <AdminIcon className="kiosk:w-96 w-36 lg:w-56 aspect-square m-auto mb-10" />
            <Input
              center
              type="number"
              className="mt-4"
              placeholder={intl.formatMessage({ id: 'admin.verifyPlaceholder', defaultMessage: 'Enter your pin here' })}
              value={pinCode}
              autoCorrect="off"
              spellCheck="false"
              error={inputError}
              inputMode="numeric"
              onKeyDown={handleKeyDown}
              onChange={handleInputChange}
              data-testid="admin-pin-code"
              inputClassName="!py-10 adminPinCode"
            />
          </div>
          <div className="flex flex-row w-full">
            <Footer
              hideNextButton
              onNext={handleNextClick}
              loading={isLoading}
              disabled={disabled}
              onPrev={handleOnPrev}
              shouldDisplayBackButton
            >
              <Button
                testId="verify-admin-code"
                loading={isLoading}
                disabled={disabled}
                type={ButtonType.TERTIARY}
                onClick={handleNextClick}
                className="max-w-md w-full kiosk:inline-flex kiosk:justify-center kiosk:w-72"
              >
                <FormattedMessage id="login.verify" defaultMessage="Verify" />
              </Button>
            </Footer>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminLogin;
