import React from 'react';
import { useLocation } from 'react-router-dom';

import PageBaseProps from 'types/PageBase';

import { UseStepsData } from 'hooks/useSteps';
import { getFollowingStep } from 'utils/stepUtils';

import { Steps } from 'components/ui';
import { UIStep } from 'components/ui/Steps';

import { ALL_STEPS, Step } from './Steps';

const LoginLayout: React.FC = () => {
  const { state } = useLocation();

  const ref = React.useRef<Pick<UseStepsData<UIStep[], PageBaseProps>, 'setStep'>>();

  const steps: Array<Step> = React.useMemo(() => {
    const visibleSteps = ALL_STEPS.filter(({ data }) => (
      typeof data.isVisible === 'boolean' ? data.isVisible : data.isVisible(state?.type)
    ));
    return visibleSteps.map((step) => ({
      ...step,
      next: getFollowingStep<Step>(step, visibleSteps, ALL_STEPS),
      previous: getFollowingStep<Step>(step, visibleSteps, ALL_STEPS, 'previous'),
    }));
  }, [state?.type]);

  const [currentStep, setCurrentStep] = React.useState(steps[0].key);

  const handleChange = (step: Step) => {
    setCurrentStep(step.key);
  };

  // Resets the flow when the steps change, ie. when the type is changed.
  // This is particularly useful when swithing from CHECKIN to BREAKDOWN in case of the appointment is not found.
  React.useEffect(() => ref.current?.setStep(steps[0].key, true), [steps]);

  return (
    <div>
      <Steps step={currentStep} steps={steps} onChange={handleChange} ref={ref} />
    </div>
  );
};

export default LoginLayout;
