enum Language {
  FR = 'fr',
  EN = 'en',
  ES = 'es',
  DE = 'de',
  IT = 'it',
  NL = 'nl',
  NO = 'no',
  PL = 'pl',
  PT = 'pt',
  SV = 'sv',
}
export default Language;
