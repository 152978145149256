import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import { useAddMessageMutation } from 'modules/chat/service';

import { getUnreadCountText, haveUnreadMessages as haveUnreadMessagesSelector } from 'modules/chat/selectors';

import Button from 'components/ui/Button';
import Modal, { ModalProps } from 'components/ui/Modal';

import Send from 'assets/icons/send.svg';

import MessageList from './MessageList';

type ChatModalProps = Pick<ModalProps, 'open' | 'onClose'> & {
  selfServiceId: string;
  maxMessageLength?: number;
};

const ChatModal: React.FC<ChatModalProps> = ({
  open, onClose, selfServiceId, maxMessageLength,
}) => {
  const intl = useIntl();

  const [message, setMessage] = React.useState('');

  const unreadCount = useSelector(getUnreadCountText);
  const hasUnreadMessages = useSelector(haveUnreadMessagesSelector);

  const [addMessage, { isLoading }] = useAddMessageMutation();

  const disabledButton = isLoading || !message || message.trim().length === 0;

  const handleChangeMessage = React.useCallback(
    ({ target }: React.ChangeEvent<HTMLTextAreaElement>) => setMessage(target.value),
    [],
  );

  const handleAddMessage = React.useCallback(async () => {
    try {
      await addMessage({ selfServiceId, message }).unwrap();
      setMessage('');
    } catch (error) {
      // Notif?
    }
  }, [addMessage, selfServiceId, message]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      className="w-full sm:w-8/12"
      title={(
        <div className="flex justify-center mb-4">
          <h2 className="relative flex gap-2 w-fit justify-center items-center">
            <FormattedMessage id="page.chat.modal.title" defaultMessage="Messages" />
            {hasUnreadMessages && (
              <div className="h-7 rounded-full p-1.5 bg-info ">
                <span className="min-w-4 flex leading-4 items-center text-xs text-white justify-center">
                  {unreadCount}
                </span>
              </div>
            )}
          </h2>
        </div>
      )}
    >
      <hr />
      <div className="flex flex-col gap-4 max-h-1/2-screen">
        <MessageList selfServiceId={selfServiceId} />
        <hr />
        <div className="flex items-center justify-between w-full gap-2">
          <div className="overflow-hidden grow rounded-3xl border">
            <textarea
              rows={2}
              value={message}
              className="w-full p-2 px-4 outline-none resize-none"
              data-testid="message-input"
              maxLength={maxMessageLength}
              onChange={handleChangeMessage}
              placeholder={intl.formatMessage({ id: 'page.chat.modal.placeholder', defaultMessage: 'Your message' })}
            />
          </div>
          <Button
            loading={isLoading}
            testId="message-send"
            disabled={disabledButton}
            onClick={handleAddMessage}
            className="size-14 bg-info shadow-md"
          >
            <Send className="w-full fill-white" />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ChatModal;
