import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Cog6ToothIcon } from '@heroicons/react/24/outline';

import { Button, Help } from 'components/ui';
import { isDevelopment } from 'utils/appUtils';
import { ButtonType } from 'components/ui/Button';
import { getKioskId } from 'modules/dealers/selectors';
import { useCheckQRCodeTokenMutation } from 'modules/auth/service';
import { isLocalKioskOffline as isKioskOfflineSelector } from 'modules/healthCheck/selectors';

import { useSelector } from '../../hooks';
import NoWifi from '../../assets/icons/noWifi.svg';

const Header = () => {
  const kioskId = useSelector(getKioskId);
  const isKioskOffline = useSelector(isKioskOfflineSelector);

  const [login] = useCheckQRCodeTokenMutation();

  const handlePrompt = () => {
    // eslint-disable-next-line no-alert
    const qrCodeToken = prompt('Please enter your QRCode token:');
    login({ qrCodeToken, kioskId });
  };

  return (
    <div className="flex justify-end relative w-full z-40 px-7 kiosk:px-12">
      {isKioskOffline && (
        <div className="absolute justify-center items-center flex w-full py-10 left-0">
          <div
            className="rounded-full text-center text-white bg-gray-50 bg-opacity-20 py-5 pl-5 pr-10 text-2xl font-bold flex items-center"
          >
            <div className="rounded-full size-10 bg-warn-bg relative flex justify-center items-center mr-5">
              <NoWifi className="absolute w-6 aspect-square" />
            </div>
            <FormattedMessage id="offline.feedback" defaultMessage="No internet connection" />
          </div>
        </div>
      )}
      {isDevelopment && (
        <div className="kiosk:block py-3 kiosk:py-10">
          <Button
            type={ButtonType.PRIMARY}
            onClick={handlePrompt}
            className="kiosk:!py-6 kiosk:!px-6 mr-6"
          >
            <div className="absolute -top-1 -left-3">
              <span className="absolute block px-4 bg-error rounded-full -rotate-30 text-white !text-lg">dev</span>
            </div>
            <Cog6ToothIcon className="size-8" />
          </Button>
        </div>
      )}
      <Help />
    </div>
  );
};

export default Header;
