import React from 'react';

import PageBaseProps from 'types/PageBase';

import PageFooter from 'components/ui/PageFooter';
import CourtesyVehicle from '.';

const CourtesyVehicleStep: React.FC<PageBaseProps> = ({ onNext, onPrev, shouldDisplayBackButton }) => (
  <div className="main-content">
    <div className="content">
      <CourtesyVehicle />
    </div>
    <PageFooter onNext={onNext} onPrev={onPrev} shouldDisplayBackButton={shouldDisplayBackButton} />
  </div>
);

export default CourtesyVehicleStep;
