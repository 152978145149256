import { isValid, parseISO } from 'date-fns';
import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { getVehicleCheckToken } from 'modules/vehicleCheck/selectors';
import { CookiesSettings, InitialState } from 'modules/auth/types/InitialState';
import {
  getContext, getLoginToken, getOffsetTimezoneByDealerId, getTimezoneByDealerId, isPublicDevice,
} from 'modules/dealers/selectors';
import {
  ADMIN_TOKEN_ENDPOINTS,
  LOGIN_TOKEN_ENDPOINTS,
  TOKEN_EXCLUDED_ENDPOINTS,
} from 'constants/url';

import type { RootState } from '../../App/Store';
import { ErrorTypeEnum } from '../../types/Error';

const getProps = (_: RootState, props: string) => props;

const getRoot = (root:RootState):RootState => root;

export const getState = (state: RootState):InitialState => state.auth;
export const getToken = createDraftSafeSelector(getState, ({ token }) => token);
export const getExpiresIn = createDraftSafeSelector(getState, ({ expiresIn }) => expiresIn);
export const getSelectedDealerId = createDraftSafeSelector(getState, ({ dealerId }) => dealerId);
export const getSelectedSelfServiceId = createDraftSafeSelector(getState, ({ selfServiceId }) => selfServiceId);
export const getMFAData = createDraftSafeSelector(getState, (state) => state.mfa);
export const getMFAError = createDraftSafeSelector(getState, (state) => state.mfaError);

export const getLoginState = createDraftSafeSelector(getState, (state) => state.login ?? {});
export const isLoadingLogin = createDraftSafeSelector(getLoginState, (login) => login.isLoading);
export const getErrorLogin = createDraftSafeSelector(getLoginState, (login) => login.error);
export const getLoginData = createDraftSafeSelector(getLoginState, (login) => login.data ?? {});
export const hasLocationIdError = createDraftSafeSelector(
  getErrorLogin,
  (error) => error?.data?.errorType === ErrorTypeEnum.SO_WRONG_SHAREBOX_LOCATION_ID,
);
export const hasRegistrationError = createDraftSafeSelector(
  getErrorLogin,
  (error) => error?.data?.errorType === ErrorTypeEnum.SO_WRONG_REGISTRATION,
);
export const getAdminCodeAttempt = createDraftSafeSelector(getState, (state) => state.adminCodeAttempt);
export const getQRCodeToken = createDraftSafeSelector(getState, (state) => state.qrCodeToken);
export const getAdminToken = createDraftSafeSelector(getState, (state) => state.adminToken);
export const hasAdminToken = createDraftSafeSelector(getAdminToken, (token) => Boolean(token));

const getSchedule = createDraftSafeSelector(getState, (state) => state.schedule);

export const getScheduledTime = createDraftSafeSelector(
  getProps,
  getSchedule,
  (type, schedule = {}) => (schedule[type] ? parseISO(schedule[type]) : undefined),
);

export const hasScheduledTime = createDraftSafeSelector(getScheduledTime, (time) => isValid(time));

export const getAuthorisationToken = createDraftSafeSelector(
  getToken,
  getLoginToken,
  getAdminToken,
  getVehicleCheckToken,
  getProps,
  (userToken, loginToken, adminToken, vehicleCheckToken, endpoint) => {
    if (!TOKEN_EXCLUDED_ENDPOINTS.includes(endpoint)) {
      if (LOGIN_TOKEN_ENDPOINTS.includes(endpoint)) {
        return loginToken;
      }

      if (ADMIN_TOKEN_ENDPOINTS.includes(endpoint)) {
        return adminToken;
      }

      return vehicleCheckToken ?? userToken;
    }

    return null;
  },
);

export const getPublicToken = createDraftSafeSelector(getState, ({ publicToken }) => publicToken);

export const getVehicleCheckPublicToken = createDraftSafeSelector(
  getState,
  ({ vehicleCheckPublicToken }) => vehicleCheckPublicToken,
);

export const hasKioskRootAccess = createDraftSafeSelector(getState, ({ kioskRootAccess }) => kioskRootAccess ?? false);

export const getEnabledCookies = createDraftSafeSelector(getState, ({ cookies }) => cookies ?? []);

export const hasCookieEnabled = createDraftSafeSelector(getEnabledCookies, (cookies) => cookies.length > 0);

export const showCookieSettings = createDraftSafeSelector(
  getContext,
  isPublicDevice,
  hasCookieEnabled,
  (context, isPublic, hasCookies) => Boolean(context) && !isPublic && !hasCookies,
);

export const hasEnabledCookie = createDraftSafeSelector(
  getEnabledCookies,
  isPublicDevice,
  getProps,
  (cookies, isPublic, setting:CookiesSettings) => isPublic || cookies.includes(setting),
);

export const canCollectAnalyticsData = createDraftSafeSelector(
  getRoot,
  (root) => hasEnabledCookie(root, CookiesSettings.ANALYTICS),
);

export const isVehicleCheckEnabled = createDraftSafeSelector(
  getMFAData,
  getContext,
  (mfa, context) => mfa?.vehicleCheckEnabled ?? context?.vehicleCheckEnabled ?? false,
);

export const getOffsetTimezone = createDraftSafeSelector(
  getRoot,
  getSelectedDealerId,
  getOffsetTimezoneByDealerId,
);

export const getTimezone = createDraftSafeSelector(
  getRoot,
  getSelectedDealerId,
  getTimezoneByDealerId,
);

export const getNotFoundSelfService = createDraftSafeSelector(getState, ({ notFound }) => notFound);
