import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormRenderProps } from 'react-final-form';

import PageBaseProps from 'types/PageBase';
import FormKeys from 'modules/form/types/FormKeys';
import { QuestionsForm as QuestionsFormType } from 'types/QuestionsForm';

import PageFooter from 'components/ui/PageFooter';

import Questions from './Questions';

const QuestionsFormRender: React.FC<FormRenderProps<QuestionsFormType> & Pick<PageBaseProps, 'onPrev' | 'shouldDisplayBackButton'>> = ({
  onPrev,
  handleSubmit,
  hasValidationErrors,
  shouldDisplayBackButton,
}) => (
  <div className="main-content max-w-3xl">
    <div className="content">
      <Questions name={FormKeys.QUESTIONS} />
    </div>
    <PageFooter
      onPrev={onPrev}
      onNext={handleSubmit}
      disabledStyle={hasValidationErrors}
      shouldDisplayBackButton={shouldDisplayBackButton}
    />
  </div>
);

export default QuestionsFormRender;
