import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { RootState } from 'App/Store';

import { messagesAdapter } from './adapters';
import { groupMessagesByDate } from './utils';
import { MAX_UNREAD_SIZE, PAGE_SIZE } from './constants';

const getState = (state: RootState) => state.chat;

const getPage = createDraftSafeSelector(
  getState,
  ({ page }) => page,
);

export const getPageNumber = createDraftSafeSelector(
  getPage,
  (page) => page?.number ?? 0,
);

export const getTotalPages = createDraftSafeSelector(
  getPage,
  (page) => page?.totalPages ?? 0,
);

const getMessages = createDraftSafeSelector(
  getState,
  ({ messages }) => messages,
);

const getAllMessages = createDraftSafeSelector(
  getMessages,
  messagesAdapter.getSelectors().selectAll,
);

const getSortedMessages = createDraftSafeSelector(
  getAllMessages,
  (messages) => messages?.toReversed() ?? [],
);

export const getGroupedMessages = createDraftSafeSelector(
  getSortedMessages,
  (messages) => groupMessagesByDate(messages),
);

export const getUnreadMessageCount = createDraftSafeSelector(
  getAllMessages,
  (messages) => messages.reduce((acc, { unread }) => (unread ? acc + 1 : acc), 0),
);

export const getFirstUnreadId = createDraftSafeSelector(
  getSortedMessages,
  (messages) => messages.find(({ unread }) => unread)?.id,
);

export const getFirstMessageId = createDraftSafeSelector(
  getSortedMessages,
  (messages) => messages[PAGE_SIZE - 1]?.id,
);

export const getLastMessage = createDraftSafeSelector(
  getSortedMessages,
  (messages) => messages[messages.length - 1],
);

export const hasMessages = createDraftSafeSelector(
  getSortedMessages,
  (messages) => messages.length > 0,
);

export const getUnreadCount = createDraftSafeSelector(
  getState,
  ({ unreadCount }) => unreadCount,
);

export const haveUnreadMessages = createDraftSafeSelector(
  getUnreadCount,
  (unreadCount) => unreadCount > 0,
);

export const getUnreadCountText = createDraftSafeSelector(
  getUnreadCount,
  (unreadCount) => `${Math.min(unreadCount, MAX_UNREAD_SIZE)}${unreadCount > MAX_UNREAD_SIZE ? '+' : ''}`,
);
