import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { useSelector } from 'hooks';
import useQrCodeScanner from 'hooks/useQrCodeScanner';

import { useGetContextQuery } from 'modules/dealers/service';

import { displayOriginSelection, hasErrorOnKiosk } from 'modules/dealers/selectors';

import Header from './Header';
import Background from './Background';
import KioskError from '../Errors/KioskError';
import KioskWarningModal from './KioskWarningModal';
import useLoginRedirection, { State } from './useLoginRedirection';
import SelfServiceTypeButtons from './SelfServiceTypeButtons';
import SelfServiceOriginButtons from './SelfServiceOriginButtons';

import './index.css';

export type HomeButtonProps = {
  onClick: (state: State) => void,
};

const CONTEXT_POLLING_INTERVAL = 60000;

const Home: React.FC = () => {
  useQrCodeScanner();

  const hasKioskError = useSelector(hasErrorOnKiosk);
  const displayOriginButtons = useSelector(displayOriginSelection);

  const [showPopup, setShowPopup] = React.useState(false);

  const handleClick = useLoginRedirection({ onError: setShowPopup });

  const { data: contextData, isLoading } = useGetContextQuery(undefined, {
    pollingInterval: CONTEXT_POLLING_INTERVAL,
  });

  const {
    dealerName, brand, type, kioskName, kioskBackgroundMedia, backgroundMedia,
  } = contextData ?? {};

  const homeTitle = (!displayOriginButtons && kioskName) || dealerName;

  return (
    <Background
      loading={isLoading}
      media={kioskBackgroundMedia ?? backgroundMedia}
    >
      <Header />
      <div className="flex flex-col justify-between grow w-full max-w-7xl z-30">
        <div className="w-full max-w-7xl m-auto text-white mt-8 grow sm:grow-0 px-7">
          <div className="flex items-center flex-col">
            {brand && (
              <div className="size-24 md:w-32 md:h-32" data-testid="logo">
                <div className="bg-white p-3 size-full flex mask-image">
                  <img src={brand.logoPathMobile} alt={brand.name} className="m-auto logo-home" />
                </div>
              </div>
            )}
            {homeTitle && (
              <h1
                className={classNames('home drop-shadow-md opacity-70 uppercase font-bold', {
                  'truncate w-full': kioskName,
                })}
              >
                {homeTitle}
              </h1>
            )}
            <h2 className="home drop-shadow-md">
              <FormattedMessage
                id="home.title"
                // eslint-disable-next-line max-len
                defaultMessage="{type, select, CHECK_IN {Ready for your check-in} CHECK_OUT {Ready for your check-out} other {How can we help you} }?"
                values={{ type }}
              />
            </h2>
          </div>
        </div>

        {displayOriginButtons && <SelfServiceOriginButtons onClick={handleClick} />}
        {!displayOriginButtons && (
          <>
            {hasKioskError && <KioskError />}
            {!hasKioskError && <SelfServiceTypeButtons onClick={handleClick} />}
          </>
        )}

        {showPopup && <KioskWarningModal onClose={setShowPopup} />}
      </div>
    </Background>
  );
};

export default Home;
