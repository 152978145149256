import React from 'react';

import Help from './Help';
import StepsHeader, { StepsHeaderProps } from './StepsHeader';

const PageHeader: React.FC<StepsHeaderProps> = ({
  currentIndex,
  length,
  title,
  onPrev,
  hideSteps,
  hidePrevButton,
}) => (
  <div className="w-full flex justify-between items-center z-30 px-6 kiosk:px-12 fixed bg-white/90 backdrop-blur-sm">
    <StepsHeader
      title={title}
      currentIndex={currentIndex}
      length={length}
      onPrev={onPrev}
      hideSteps={hideSteps}
      hidePrevButton={hidePrevButton}
    />
    <Help />
  </div>
);

export default PageHeader;
