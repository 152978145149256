import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFieldArray } from 'react-final-form-arrays';
import { FormRenderProps, useField } from 'react-final-form';

import PageBaseProps from 'types/PageBase';
import ExtraStatus from 'types/ExtraStatus';
import { DocumentStatus, MobilityDocument } from 'modules/selfServices/types/SelfService';

import { b } from 'utils/i18nUtils';
import { required } from 'utils/formUtils';

import { isMobilityAccepted } from 'modules/selfServices/selectors';

import Card from 'components/ui/Card';
import PageFooter from 'components/ui/PageFooter';
import { booleanFormat, booleanParse } from 'components/ui/Card/utils';
import { useUpdateSelfServiceMutation } from 'modules/selfServices/service';

import Document from './Document';

const MobilityDocumentFormRender: React.FC<FormRenderProps & PageBaseProps> = ({
  isPenultimate,
  handleSubmit,
  errors = {},
  onPrev,
  shouldDisplayBackButton,
}) => {
  const intl = useIntl();
  const [, { isLoading: isUpdateLoading }] = useUpdateSelfServiceMutation({ fixedCacheKey: 'UPDATE/SELF_SERVICE' });
  const {
    value: mobilityStatus,
    onChange: handleChangeMobilityStatus,
  } = useField<boolean, HTMLElement, ExtraStatus>('customerAccepted', {
    validate: required,
    parse: booleanParse,
    format: booleanFormat,
    subscription: { value: true },
  }).input;
  const { fields: formDocuments } = useFieldArray<MobilityDocument>('documents', { subscription: { value: true } });

  const mobilityAlreadyAccepted = useSelector(isMobilityAccepted);

  const hasValidationErrors = Object.keys(errors).length > 0;
  const documents = React.useMemo(() => {
    const documentsFormNames = formDocuments.map((name) => name) ?? [];
    const { value = [] } = formDocuments;
    return value.reduce((acc, { status }, index) => {
      if (status === DocumentStatus.REQUESTED) {
        acc.requested.push(documentsFormNames[index]);
      } else if (status === DocumentStatus.REFUSED) {
        acc.refused.push(documentsFormNames[index]);
      }

      return acc;
    }, { requested: [], refused: [] });
  }, [formDocuments]);

  const hasRefusedDocuments = documents.refused.length > 0;
  const hasRequestedDocuments = documents.requested.length > 0;
  const showRefusedDocuments = hasRefusedDocuments && mobilityAlreadyAccepted;
  const showRequestedDocuments = hasRequestedDocuments
    && (mobilityAlreadyAccepted || mobilityStatus === ExtraStatus.ACCEPTED);

  return (
    <div className="main-content">
      {!mobilityAlreadyAccepted && (
        <div>
          <Card
            mandatory
            status={mobilityStatus}
            onStatusChange={handleChangeMobilityStatus}
            titleClassName="grid-cols-1 justify-center"
            title={intl.formatMessage({
              id: 'mobilityDocument.question',
              defaultMessage: 'Do you still want a courtesy vehicle?',
            })}
          />
          {showRequestedDocuments && (
            <>
              <div className="h-px w-full bg-divider-bg mb-5 kiosk:mb-14" />
              <p className="text-center">
                <FormattedMessage
                  id="mobilityDocument.verify"
                  defaultMessage="In order to provide you with a courtesy vehicle, we need to <b>verify your information.</b>"
                  values={{ b }}
                />
              </p>
            </>
          )}
        </div>
      )}
      {showRefusedDocuments && (
        <>
          <p className="text-center mb-3">
            <FormattedMessage
              id="mobilityDocument.refused"
              defaultMessage="The following documents have <b>been refused :</b>"
              values={{ b }}
            />
          </p>
          <div className="w-full rounded-lg border border-border-bg divide-y">
            {documents.refused.map((name, index) => <Document name={name} key={formDocuments.value[index].id} />)}
          </div>
          <div className="h-px w-full bg-divider-bg kiosk:mb-14" />
        </>
      )}
      {showRequestedDocuments && (
        <>
          <p className="text-center">
            <FormattedMessage
              id="mobilityDocument.takePicture"
              defaultMessage="Please take pictures of the documents requested below."
            />
          </p>
          <div className="w-full rounded-lg border border-border-bg divide-y mb-28">
            {documents.requested.map((name, index) => <Document name={name} key={formDocuments.value[index].id} />)}
          </div>
        </>
      )}
      <PageFooter
        onPrev={onPrev}
        onNext={handleSubmit}
        loading={isUpdateLoading}
        isPenultimate={isPenultimate}
        disabledStyle={hasValidationErrors}
        shouldDisplayBackButton={shouldDisplayBackButton}
      />
    </div>
  );
};

export default MobilityDocumentFormRender;
