/* eslint-disable react/style-prop-object */
import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import type PageBaseProps from 'types/PageBase';

import {
  useGetSelfServiceByIdQuery,
  useUpdateSelfServiceMutation,
} from 'modules/selfServices/service';
import { b } from 'utils/i18nUtils';

import { getSelectedSelfServiceId } from 'modules/auth/selectors';

import PageFooter from 'components/ui/PageFooter';
import FinalInvoiceCard from './FinalInvoiceCard';

const FinalInvoiceTab: React.FC<PageBaseProps> = ({
  onNext, onPrev, isPenultimate, shouldDisplayBackButton,
}) => {
  const selfServiceId = useSelector(getSelectedSelfServiceId);

  const { data: selfService } = useGetSelfServiceByIdQuery({ id: selfServiceId });
  const [, { isLoading }] = useUpdateSelfServiceMutation({ fixedCacheKey: 'UPDATE/SELF_SERVICE' });

  const { finalInvoice, dealer } = selfService;
  const { payable: isInvoicePayable } = finalInvoice;

  const description = isInvoicePayable ? {
    id: 'page.finalInvoice.introduction',
    // eslint-disable-next-line max-len
    defaultMessage: 'In order to complete your <b>check-out</b>, we kindly ask you to <b>pay the following invoice</b> before picking-up your vehicle.',
  } : {
    id: 'page.finalInvoice.information',
    defaultMessage: 'For information, please find below the invoice for your appointment.',
  };

  return (
    <div className="main-content">
      <p className="text-center mt-5 text-gray-70">
        <FormattedMessage
          {...description}
          // eslint-disable-next-line max-len
          values={{ b }}
        />
      </p>
      <div
        className={classNames('content kiosk:!max-w-2xl', {
          'pb-6': isInvoicePayable,
        })}
      >
        <FinalInvoiceCard
          onSkip={onNext}
          finalInvoice={finalInvoice}
          selfServiceId={selfServiceId}
          currencyIsoCode={dealer.currencyIsoCode}
        />
      </div>
      <PageFooter
        onNext={onNext}
        onPrev={onPrev}
        loading={isLoading}
        isPenultimate={isPenultimate}
        hideNextButton={isInvoicePayable}
        shouldDisplayBackButton={shouldDisplayBackButton}
      />
    </div>
  );
};

export default FinalInvoiceTab;
