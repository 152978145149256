import { REHYDRATE } from 'redux-persist';
import { createListenerMiddleware } from '@reduxjs/toolkit';

import api from 'service/index';
import { getUrlParam } from 'utils/urlUtils';
import healthCheckApi from 'modules/healthCheck/service';
import { isLocalKiosk } from 'modules/dealers/selectors';
import { AppStartListening } from 'App/ListenerMiddleware';

const listenerMiddleware = createListenerMiddleware();

const startAppListening = listenerMiddleware.startListening as AppStartListening;

export const HEALTH_CHECK_POLLING_INTERVAL = 10000;

startAppListening({
  predicate: (action, state) => {
    const outdoorShareboxToken = getUrlParam('OSToken');
    return (action.type === REHYDRATE || action.type === api.util.resetApiState.type)
      && Boolean(isLocalKiosk(state) || outdoorShareboxToken);
  },
  effect: async (_, { dispatch }) => {
    dispatch(healthCheckApi.endpoints.healthCheck.initiate(
      undefined,
      { subscriptionOptions: { pollingInterval: HEALTH_CHECK_POLLING_INTERVAL } },
    ));
  },
});

export default listenerMiddleware;
