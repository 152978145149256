import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { hasErrorOnKiosk, isKioskFull as isKioskFullSelector } from 'modules/dealers/selectors';

import Popup from 'components/ui/Popup';
import KioskError from 'components/Errors/KioskError';

const WARNING_MODAL_DURATION = 8000;

interface KioskWarningModalProps {
  onClose?: (value: boolean) => void;
}

const KioskWarningModal: React.FC<KioskWarningModalProps> = ({ onClose }) => {
  const hasKioskError = useSelector(hasErrorOnKiosk);
  const isKioskFull = useSelector(isKioskFullSelector);

  return (
    <Popup onClose={onClose} duration={WARNING_MODAL_DURATION} className="!bg-input-bg">
      {hasKioskError && <KioskError />}
      {!hasKioskError && isKioskFull && (
        <h2 className="py-16 text-center">
          <FormattedMessage
            id="kiosk.fullWarning"
            defaultMessage="The kiosk is full. It is no longer possible to drop keys."
          />
        </h2>
      )}
    </Popup>
  );
};

export default KioskWarningModal;
