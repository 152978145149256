import { matchPath } from 'react-router-dom';
import {
  createSlice, isAnyOf, isFulfilled, isPending, isRejected, PayloadAction,
} from '@reduxjs/toolkit';

import { getUrlParam } from 'utils/urlUtils';
import authService from 'modules/auth/service';
import { locationChange } from 'modules/router/actions';
import {
  CookiesSettings, InitialState, LOCAL_STORAGE_KEY, LoginData, Schedule,
} from 'modules/auth/types/InitialState';

import vehicleCheckApi from 'modules/vehicleCheck/service';
import type { OpenKeysSafeRequest } from 'modules/kiosk/types/KeysSafe';

import { APIError, ErrorTypeEnum, WrongKioskErrorMetadata } from 'types/Error';
import { login } from './thunk';

// undefined values are here to reset state, for instance when logging out
export const initialState: InitialState = {
  login: { isLoading: false, error: null, data: null },
  dealerId: undefined,
  expiresIn: undefined,
  mfa: undefined,
  mfaError: undefined,
  publicToken: getUrlParam('token'),
  selfServiceId: undefined,
  token: undefined,
  qrCodeToken: undefined,
  adminToken: undefined,
  adminCodeAttempt: 0,
  schedule: undefined,
  vehicleCheckPublicToken: undefined,
  kioskRootAccess: undefined,
  cookies: JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) ?? [],
  notFound: false,
};

interface LogoutMeta {
  asAdmin?: boolean;
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: {
      prepare: (payload: Partial<OpenKeysSafeRequest> = {}, meta: LogoutMeta = {}) => ({
        meta,
        payload,
        error: undefined,
      }),
      reducer: (state) => ({
        ...initialState,
        cookies: state.cookies,
      }),
    },
    resetAdminCodeAttempt: (state) => ({ ...state, adminCodeAttempt: 0 }),
    resetSchedule: (state) => ({
      ...state,
      schedule: undefined,
    }),
    setCookies: (state, { payload: cookies = [] }: PayloadAction<CookiesSettings[]>) => ({ ...state, cookies }),
    setSchedule: (state, { payload }: PayloadAction<Schedule>) => ({
      ...state,
      schedule: payload,
    }),
    setLoginData: (state, { payload }: PayloadAction<LoginData>) => ({
      ...state,
      login: {
        ...state.login,
        data: payload,
      },
    }),
    setNotFound: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      notFound: payload,
    }),
  },
  extraReducers: (builder) => {
    // eslint-disable-next-line max-len
    builder.addCase(locationChange, (state, { payload }) => {
      const isHomeLocation = matchPath('/', payload.location.pathname);
      const isLoginLocation = matchPath('/login', payload.location.pathname);

      if (isLoginLocation || isHomeLocation) {
        const currentData = state.login.data;
        return {
          ...state,
          login: { error: null, isLoading: false, data: (isHomeLocation ? null : currentData) },
          mfaError: undefined,
          notFound: false,
        };
      }
      return state;
    });
    builder.addMatcher(
      isRejected(login),
      (state, { payload }) => ({ ...state, login: { ...state.login, error: payload, isLoading: false } }),
    );
    builder.addMatcher(
      isPending(login),
      (state) => ({ ...state, login: { ...state.login, error: null, isLoading: true } }),
    );
    builder.addMatcher(isFulfilled(login), (state) => ({
      ...state,
      login: {
        ...state.login,
        error: null,
        isLoading: false,
      },
      schedule: undefined,
    }));
    builder.addMatcher(
      isAnyOf(authService.endpoints.login.matchFulfilled, authService.endpoints.loginMFA.matchFulfilled),
      (state, action) => ({ ...state, ...action.payload }),
    );

    // Avoid BMWVID-18704 - Mobility warning displayed when it should not
    builder.addMatcher(authService.endpoints.getMFAConnection.matchPending, (state) => ({
      ...state,
      mfa: undefined,
    }));
    builder.addMatcher(authService.endpoints.getMFAConnection.matchFulfilled, (state, action) => {
      const { selfServiceId, ...mfa } = action.payload;
      return { ...state, selfServiceId, mfa: { ...state.mfa, ...mfa } };
    });
    builder.addMatcher(authService.endpoints.getMFAConnection.matchRejected, (state, action) => {
      const { status } = action.payload;

      return {
        ...state,
        notFound: status === 404,
      };
    });
    builder.addMatcher(authService.endpoints.sendMFACode.matchFulfilled, (state, action) => ({
      ...state,
      mfa: { ...state.mfa, ...action.payload },
    }));
    builder.addMatcher(authService.endpoints.loginAdmin.matchRejected, (state) => ({
      ...state,
      adminCodeAttempt: (state.adminCodeAttempt ?? 0) + 1,
    }));
    builder.addMatcher(authService.endpoints.loginAdmin.matchFulfilled, (state, action) => ({
      ...state,
      adminCodeAttempt: 0,
      adminToken: action.payload.token,
      schedule: undefined,
      kioskRootAccess: action.payload.kiosk_root_access,
      dealerId: action.payload.dealer_id ?? action.payload.dealer_ids,
    }));

    builder.addMatcher(vehicleCheckApi.endpoints.getVCLink.matchFulfilled, (state, { payload }) => {
      const url = new URL(payload.url);
      const token = url.searchParams.get('token');
      return {
        ...state,
        vehicleCheckPublicToken: token,
      };
    });

    builder.addMatcher(authService.endpoints.getMFAConnection.matchRejected, (state, action) => {
      const data = action.payload.data as APIError<ErrorTypeEnum.SO_WRONG_KIOSK, WrongKioskErrorMetadata>;
      return data?.errorType === ErrorTypeEnum.SO_WRONG_KIOSK ? {
        ...state,
        mfaError: {
          ...data.errorMetadata,
          type: ErrorTypeEnum.SO_WRONG_KIOSK,
        },
      } : state;
    });

    builder.addMatcher(authService.endpoints.checkQRCodeToken.matchFulfilled, (state, action) => {
      const { qrCodeToken } = action.meta.arg.originalArgs;
      return {
        ...state,
        qrCodeToken,
      };
    });

    builder.addMatcher(locationChange.match, (state, { payload }) => (
      matchPath('/', payload.location.pathname) ? { ...state, qrCodeToken: undefined } : state));
  },
});

export default authSlice;
